<template>
  <form id="formCadastroAtoresJuridicos" action="" method="post" class="form-custom bg-white p-5" :class="{ 'was-validated': wasValidated}" @submit="checkForm">
    <div class="row w-100 mb-5">
      <button class="col p-2 pb-3 btn-step" :class="{ active: step === 1}" type="button" @click="tryGoTo(1)">1. Informações da Organização</button>
      <button class="col p-2 pb-3 btn-step" :class="{ active: step === 2}" type="button" @click="tryGoTo(2)">2. Relação com IU</button>
      <button class="col p-2 pb-3 btn-step" :class="{ active: step === 3}" type="button" @click="tryGoTo(3)">3. Associações</button>
      <div class="progress p-0" style="height: 3px;">
        <div class="progress-bar" role="progressbar" :style="{ width: (step / 3 * 100) + '%' }" :aria-valuenow="(step / 3 * 100)" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>

    <input v-if="this.isUpdating" type="hidden" name="id" :value="registry.id" >
    <input type="hidden" name="tipo_do_ator" value="juridico">

    <fieldset v-show="step === 1" class="row w-100" :disabled="sendingState || readOnlyMode">
      <div class="row">
        <div class="col-6 mb-6 text-center">
            <template v-if="previewImage !== null">
              <img :src="previewImage" alt="" class="image_perfil" name="foto_perfil_img">
            </template>
            <template v-else>
              <img src="../../assets/user.png" class="image_perfil" name="foto_perfil_img" value="">
            </template>

          <h3 class="m-3">Imagem do perfil</h3>
          <input type="file" accept="image/*" @change=uploadImage >
          <input type="hidden" v-if="uploadImage !== null" name="foto_perfil" id="foto_perfil">

          <p v-show="previewImage === null" style="max-width: 248px; margin: 17px auto;">Adicione uma imagem para visualizar este ator no mapa mais facilmente.</p>
          <!-- <button class="btn btn-primary px-4 py-2 rounded-pill">
              Adicionar imagem
          </button> -->
        </div>
        <div class="col-6 mb-6">
        <div class="col-12 mb-3">
          <label for="inputnome_do_ator" class="form-label fw-bold">Nome fantasia da Organização*</label>
          <input type="text" class="form-control" id="inputnome_do_ator" name="nome_do_ator" placeholder="Digite aqui" required>
          <div class="invalid-feedback">
            O nome da organização é obrigatório
          </div>
        </div>
        <div class="col-12 mb-3">
          <label for="inputcpf_cnpj" class="form-label fw-bold">CNPJ</label>
          <input type="text" class="form-control" id="inputcpf_cnpj" name="cpf_cnpj" placeholder="Digite aqui o CNPJ">
        </div>
        <div class="col-12 mb-3">
          <label for="inputtipo_de_organizacao" class="form-label fw-bold">Tipo de organização*</label>
          <select id="inputtipo_de_organizacao" name="tipo_de_organizacao" class="form-select" aria-label="Selecione o tipo de organização" required>
            <option hidden selected disabled  value="">Selecione uma das opções</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
          <div class="invalid-feedback">
            Informar o tipo de organização é obrigatório
          </div>
        </div>
        <div class="col-12 mb-3">
          <label for="inputemail" class="form-label fw-bold">E-mail</label>
          <input type="text" class="form-control" id="inputemail" name="email" placeholder="Digite aqui o e-mail" :value="email" v-if="this.email !== null" readonly>
          <input type="text" class="form-control" id="inputemail" name="email" placeholder="Digite aqui o e-mail" v-if="this.email === null">
        </div>
         <div class="col-12 mb-3">
        <label for="inputtelefone" class="form-label fw-bold">Telefone</label>
        <input type="text" class="form-control" id="inputtelefone" name="telefone" placeholder="Digite aqui o telefone">
      </div>
       </div>
       </div>
      <div class="col-12">
        <hr class="my-4">
        <h4 class="mb-4">
          Mídias sociais
        </h4>
      </div>
      <div class="col-6 mb-3">
        <label for="inputurl_facebook" class="form-label fw-bold">Facebook</label>
        <input type="url" class="form-control" id="inputurl_facebook" name="url_facebook" placeholder="Digite aqui">
      </div>
      <div class="col-6 mb-3">
        <label for="inputurl_linkedin" class="form-label fw-bold">Linkedin</label>
        <input type="url" class="form-control" id="inputurl_linkedin" name="url_linkedin" placeholder="Digite aqui">
      </div>
      <div class="col-6 mb-3">
        <label for="inputurl_instagram" class="form-label fw-bold">Instagram</label>
        <input type="url" class="form-control" id="inputurl_instagram" name="url_instagram" placeholder="Digite aqui">
      </div>
      <div class="col-6 mb-3">
        <label for="inputurl_outra_midia" class="form-label fw-bold">Site</label>
        <input type="url" class="form-control" id="inputurl_outra_midia" name="url_outra_midia" placeholder="Digite aqui">
      </div>
    </fieldset>

    <fieldset v-show="step === 2" class="row w-100" :disabled="sendingState || readOnlyMode">
      <div class="col-6 mb-3">
        <label class="form-label fw-bold">Relação com o Instituto Unibanco</label>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="relacao_unibanco" id="inputrelacao_unibanco1" value="Sim">
          <label class="form-check-label" for="inputrelacao_unibanco1">
            Sim
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="relacao_unibanco" id="inputrelacao_unibanco2" value="Não">
          <label class="form-check-label" for="inputrelacao_unibanco2">
            Não
          </label>
        </div>
      </div>
      <div class="col-6 mb-3">
        <label class="form-label fw-bold">Relação com CdG</label>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="relacao_cdg" id="inputrelacao_cdg1" value="Sim">
          <label class="form-check-label" for="inputrelacao_cdg1">
            Sim
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="relacao_cdg" id="inputrelacao_cdg2" value="Não">
          <label class="form-check-label" for="inputrelacao_cdg2">
            Não
          </label>
        </div>
      </div>
      <div class="col-6 mb-3">
        <label for="inputfuncao_programa" class="form-label fw-bold">Função no programa</label>
        <select id="inputfuncao_programa" name="funcao_programa" class="form-select" aria-label="Selecione a função">
          <option hidden selected disabled  value="">Selecione uma das opções</option>
          <option value="1">One</option>
          <option value="2">Two</option>
        </select>
      </div>
      <div class="col-6 mb-3">
        <label for="inputresponsabilidade_no_programa" class="form-label fw-bold">Responsabilidade e atribuições</label>
        <input type="text" class="form-control" id="inputresponsabilidade_no_programa" name="responsabilidade_no_programa" placeholder="Digite aqui">
      </div>
      <div class="col-6 mb-3">
        <label for="inputgrau_de_poder" class="form-label fw-bold">Poder</label>
        <select id="inputgrau_de_poder" name="grau_de_poder" class="form-select" aria-label="Selecione o grau de poder">
          <option hidden selected disabled  value="">Selecione uma das opções</option>
          <option value="muito-baixo">Muito baixo</option>
          <option value="baixo">Baixo</option>
          <option value="medio">Médio</option>
          <option value="alto">Alto</option>
          <option value="muito-alto">Muito alto</option>
        </select>
      </div>
      <div class="col-6 mb-3">
        <label for="inputgrau_de_interesse" class="form-label fw-bold">Interesse</label>
        <select id="inputgrau_de_interesse" name="grau_de_interesse" class="form-select" aria-label="Selecione o grau de interesse">
          <option hidden selected disabled  value="">Selecione uma das opções</option>
          <option value="muito-baixo">Muito baixo</option>
          <option value="baixo">Baixo</option>
          <option value="medio">Médio</option>
          <option value="alto">Alto</option>
          <option value="muito-alto">Muito alto</option>
        </select>
      </div>
      <div class="col-6 mb-3 d-flex flex-wrap">
        <div class="col-12 mb-3">
          <label for="inputcampos_incidencias_e_articulacoes" class="form-label fw-bold">Campos e incidência e articulação</label>
          <input type="text" class="form-control" id="inputcampos_incidencias_e_articulacoes" name="campos_incidencias_e_articulacoes" placeholder="Digite aqui">
        </div>
        <div class="col-12 mb-3">
          <label for="inputavalicao_de_perfil" class="form-label fw-bold">Avaliação de perfil</label>
          <input type="text" class="form-control" id="inputavalicao_de_perfil" name="avalicao_de_perfil" placeholder="Digite aqui">
        </div>
      </div>
      <div class="col-6 mb-3">
        <label for="inputpotencialidades" class="form-label fw-bold">Potencialidades</label>
        <textarea class="form-control" id="inputpotencialidades" name="potencialidades" rows="6" placeholder="Digite aqui"></textarea>
      </div>
    </fieldset>

    <fieldset v-show="step === 3" class="row w-100" :disabled="sendingState || readOnlyMode">
      <ActorSearch :badgeClickCallback="addSelected" />
      <!-- Exibe os atores selecionados -->
      <div>
        Dados que serão sincronizados
      </div>
      <div class="atores-com-tipo d-flex">
        <div v-for="actor of actorSelected" :key="actor.id" class="bg-secondary rounded-pill py-1 px-3 text-white m-1">
          {{ actor.nome_do_ator }} tipo de relação {{ actor.tipo }}
        </div>
      </div>
    </fieldset>

    <div class="row w-100 justify-content-center mt-5">
      <button v-if="step > 1" type="button" class="col-4 mx-1 btn btn-outline-primary rounded-pill"  @click="tryGoTo(step - 1)">Voltar</button>
      <button v-if="step !== 3" type="button" class="col-4 mx-1 btn btn-primary rounded-pill" @click="tryGoTo(step + 1)">Próximo</button>
      <button v-if="step === 3 && !readOnlyMode" type="submit" class="col-4 mx-1 btn btn-primary rounded-pill" :disabled="sendingState" @click="wasValidated = true">
        <span v-if="sendingState">Registrando ... <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></span>
        <span v-else>Finalizar cadastro</span>
      </button>
    </div>
  </form>

  <div class="modal-mask d-flex justify-content-center align-items-center" v-if="showModal" @close="showModal = false">
      <modal>
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 d-flex justify-content-end" style="padding-top: 10px; padding-right: 20px;">
            <button class="btn" @click="showModal = false">
              <img src="../../assets/icon-close.png" alt="">
            </button>
          </div>
        </div>
        <h3 style="padding: 0 3em;">Cadastro realizado com sucesso</h3>
        <div class="container_box">
          <p class="p-4">Agradecemos as suas informações</p>
          <div class="d-flex justify-content-between pb-5 pt-3">
            <router-link class="nav-link mb-1 text-secondary bg-gray-100" :class="{ 'active': /^Atores.*$/.test($route.name) }" :to="{ name: 'Atores' }">
              <button class="btn btn-outline-primary px-3 rounded-pill" @click="showModal = false">
                  Ir para lista de Atores
              </button>
            </router-link>
            <router-link class="nav-link mb-1 text-secondary bg-gray-100" :class="{ 'active': /^Atores.*$/.test($route.name) }" :to="{ name: 'Atores.Cadastro' }">
              <button class="btn btn-primary px-4 py-2 rounded-pill" @click="showModal = false, reloadPage()">
                  Fazer novo cadastro
              </button>
            </router-link>
          </div>
        </div>
      </modal>
  </div>
</template>

<script>
import AtoresRepository from '../../libs/repositories/AtoresRepository.js';
import Estados from '../../schemas/Estados.js';
import ActorSearch from '../../components/ActorSearch.vue';
import { AssociacoesRepository } from '../../libs/repositories/AssociacoesRepository.js';
import { useRoute } from 'vue-router';

export default {
  name: 'FormAtorJuridico',
  props: {
    registry: {
      type: Object,
      required: false,
      default: null
    },
    readOnlyMode: {
      type: Boolean,
      requried: false,
      default: false
    }
  },
  components: {
    ActorSearch
  },
  data () {
    return {
      isUpdating: !!this.registry,
      sendingState: false,
      wasValidated: false,
      step: 1,
      listaDeEstados: Estados,
      cargos: [],
      actorSelected: [],
      previewImage: null,
      showModal: false,
      email: null
    };
  },
  created () {
    this.email = useRoute().params.email;
  },
  mounted () {
    if (!this.isUpdating) return;

    /** @type {HTMLFormElement} */ // @ts-expect-error
    const form = document.getElementById('formCadastroAtoresJuridicos');

    form.nome_do_ator.value = this.registry.nome_do_ator;
    form.cpf_cnpj.value = this.registry.cpf_cnpj;
    form.tipo_de_organizacao.value = this.registry.tipo_de_organizacao;
    form.email.value = this.registry.email;
    form.telefone.value = this.registry.telefone;
    form.relacao_unibanco.value = this.registry.relacao_unibanco;
    form.relacao_cdg.value = this.registry.relacao_cdg;
    form.funcao_programa.value = this.registry.funcao_programa;
    form.responsabilidade_no_programa.value = this.registry.responsabilidade_no_programa;
    form.campos_incidencias_e_articulacoes.value = this.registry.campos_incidencias_e_articulacoes;
    form.grau_de_poder.value = this.registry.grau_de_poder;
    form.grau_de_interesse.value = this.registry.grau_de_interesse;
    form.potencialidades.value = this.registry.potencialidades;
    form.avalicao_de_perfil.value = this.registry.avalicao_de_perfil;
    form.url_facebook.value = this.registry.url_facebook;
    form.url_instagram.value = this.registry.url_instagram;
    form.url_linkedin.value = this.registry.url_linkedin;
    form.url_outra_midia.value = this.registry.url_outra_midia;
    form.foto_perfil_img.src = this.registry.foto_perfil;
  },
  methods: {
    /**
     * @param {Event} event
     */
    async checkForm (event) {
      event.preventDefault();

      /** @type {HTMLFormElement} */ // @ts-expect-error
      const form = event.target;
      const formData = new FormData(form);

      if (!form.checkValidity()) {
        this.wasValidated = true;
        return;
      }

      this.sendingState = true;

      const model = {};

      formData.forEach((value, key) => {
        model[key] = value;
      });

      const response = (this.isUpdating)
        ? await AtoresRepository.update(model.id, model)
        : await AtoresRepository.add(model);

      /**
       * @todo Por hora apenas o cadastro permite a adição de associações
       */
      if (!this.isUpdating && response.data && response.data.type === 'success') {
        const data = response.data;
        const syncReponse = await AssociacoesRepository.sync({
          deAtorId: data.id,
          criarId: this.actorSelected.map((actor) => actor.id),
          criarTipoId: this.actorSelected.map((actor) => actor.tipo)
        });
        // eslint-disable-next-line
        console.log(syncReponse);
      }

      this.sendingState = false;
      this.wasValidated = false;

      if (!this.isUpdating && response.data) {
        form.reset();
      }
      this.showModal = true;

      // eslint-disable-next-line
      // console.log(response);
    },
    /**
     * Método que aplica as regras de validação
     * @param {number} destinationStep
     */
    tryGoTo (destinationStep) {
      // Caso esteja enviando, não permite navegar entre as etapas
      if (this.sendingState) return;

      switch (destinationStep) {
        case 1:
          this.step = destinationStep;
          this.wasValidated = false;
          break;
        case 2:
        case 3: {
          /** @type {HTMLInputElement[]} */
          const inputs = [
            // @ts-expect-error
            document.getElementById('inputnome_do_ator'),
            // @ts-expect-error
            document.getElementById('inputtipo_de_organizacao')
          ];

          for (const input of inputs) {
            if (!input.reportValidity()) {
              this.wasValidated = true;
              return;
            }
          }
          // Se nenhum tiver falhado, avança para a próxima etapa
          this.step = destinationStep;
          this.wasValidated = false;
        }
          break;
        default: { throw Error(`Step inválido: ${destinationStep}`); }
      }
    },
    addSelected (actor) {
      /**
       * @todo por hora fica fixo para poder testar, porém é necessário que seja dinâmico
       */
      this.actorSelected = [...this.actorSelected, Object.assign({}, actor, { tipo: 1 })];
    },
    uploadImage (e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);

      reader.onload = e => {
        this.previewImage = e.target.result;
        document.querySelector('#foto_perfil').setAttribute('value', this.previewImage);
        console.log(this.previewImage);
      };
    }
  }
};

</script>

<style scoped>
.btn-step {
  background-color: transparent;
  color: black;
  border: none;
}
.btn-step.active {
  font-weight: bold;
}
.form-custom {
  min-height: 660px;
}
.progress-bar {
  background-color: #007BFF;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}
modal {
  background-color: white;
  display: block;
  max-width: 510px;
  width: 100%;
  border-radius: 5px;
  text-align: center;
}
h3{
  font-size: 23px;
}
.container_box{
  width: 440px;
  margin: 0 auto;
}
.image_perfil{
  width: 162px;
  height: 162px;
  border-radius: 100%;
}
</style>
